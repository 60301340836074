<script setup></script>

<template>
  <div>
    <div class="relative pl-0 pt-0 md:pl-16 md:pt-9">
      <div
        class="mx-auto h-10 w-[3.33333rem] bg-[url('/images/quote.svg')] bg-cover bg-center opacity-50 md:absolute md:left-0 md:top-0 md:mx-0 md:h-16 md:w-[5.33333rem]"
      />
      <div class="relative mt-4 md:mt-0">
        <slot />
      </div>
    </div>
    <div class="mt-4" v-if="$slots.profile">
      <slot name="profile" />
    </div>
  </div>
</template>
